import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'

// assets
import ArrowRight from '../../Shapes/ArrowRight'

const GradientButtonWrap = styled.div`
  background-color: #60ba47;

  &:hover {
    background-color: black;
    color: #60ba47;

    svg {
      color: inherit;
    }
  }

  svg {
    color: #76f935;
  }
`

const gradientButtonWrapClasses = `
  inline-block
  cursor-pointer
  text-black
  uppercase
  text-sm
  font-bold
  transition
  duration-100
  ease-in-out
`

const InternalLink = styled.div`
  a {
    ${tw`
      text-white
      no-underline
    `}
  }
`

const GradientButton = ({ children }) => (
  <GradientButtonWrap className={gradientButtonWrapClasses}>
    <InternalLink className="flex items-center py-1 px-3">
      <span style={{ marginTop: 2 }}>{children}</span>
      <div className="ml-3 flex flex-no-shrink">
        <ArrowRight />
      </div>
    </InternalLink>
  </GradientButtonWrap>
)

export default GradientButton
