import React, { useState } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import tw from 'tailwind.macro'
import PlayIconImage from '../../../../../images/media-kit/PlayIconImage.png'

const Inner = styled.div`
  ${tw`
    lg:px-0
    pb-6
    -mb-6
    mx-auto
    max-w-2xl
    px-3
    md:px-3
    lg:px-2
    xl:px-0
  `}
`

const ContainerVideoTitle = styled.div`
  ${tw`
    -mx-6
    xl:mx-0
  `}
`

const VideoContainer = styled.div`
  position: relative;
`

const PlayIcon = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`

function VideoWithTitle({ title, video, useBackground, poster }) {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoPlayer = React.createRef()

  return (
    <ContainerVideoTitle>
      <div className={classnames({ 'bg-light-grey-2': useBackground })}>
        <Inner>
          {title && (
            <h2
              className={classnames(
                'font-sans',
                'font-extrabold',
                'leadind-relaxed',
                'text-charcoal',
                'lg:text-35px',
                'py-2',
                'pt-4',
                'md:pb-4',
                'lg:pt-8',
                'text-left',
                'md:mr-9'
              )}
            >
              {title}
            </h2>
          )}
          <VideoContainer>
            <video
              className="w-full pb-0"
              src={video}
              poster={poster}
              controls={isPlaying}
              ref={videoPlayer}
              preload
              onPause={() => setIsPlaying(false)}
              onEnded={() => setIsPlaying(false)}
              onError={() => setIsPlaying(false)}
              onPlay={() => setIsPlaying(true)}
            />
            {!isPlaying && (
              <PlayIcon
                onClick={() => {
                  videoPlayer.current.play()
                }}
              >
                <img src={PlayIconImage} />
              </PlayIcon>
            )}
          </VideoContainer>
        </Inner>
      </div>
    </ContainerVideoTitle>
  )
}

export default VideoWithTitle
