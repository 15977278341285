import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import classnames from 'classnames'

import GradientButton from '../../../../../components/General/Buttons/GradientButton'
import SmartLink from '../../../../../components/General/SmartLink'
import VideoWithTitle from '../../components/VideoWithTitle'

const Inner = styled.div`
  ${tw`
    px-3
    xl:px-0
    max-w-2xl
    
  `}
  margin: 0 auto;
`
const Container = styled.div`
  ${tw`
    -mx-6
    xl:mx-0
  `}
`

const Image = styled.img`
  ${tw`
  w-full
  h-full
`}
  object-fit: cover;
`

const Title = ({ text }) => {
  return (
    <h3
      className={classnames(
        'text-35px',
        'leading-none',
        'font-sans',
        'font-extrabold',
        'hidden',
        'lg:block',
        'uppercase',
      )}
    >
      {text}
    </h3>
  )
}

const TitleMobile = ({ text }) => {
  return (
    <h3
      className={classnames(
        'text-35px',
        'md:leading-relaxed',
        'leading-thig',
        'font-sans',
        'font-extrabold',
        'lg:hidden',
        'my-2',
        'lg:my-0'
      )}
    >
      {text}
    </h3>
  )
}

function FiftyFifty({
  title,
  description,
  cta,
  media,
  inverse,
  useBackground,
  poster,
  isVideo,
}) {
  const TextColumn = () => (
    <div className="w-full lg:w-1/2">
      <div className={inverse ? 'lg:ml-9' : 'lg:mr-9'}>
        <Title text={title} />
        <div
          className={classnames(
            'font-sans',
            'leading-relaxed',
            'text-charcoal',
            'font-thin',
            'md:mt-5',
            'md:mb-7',
            'mb-3',
            'mt-2'
          )}
        >
          {description}
        </div>
      </div>
      {cta && cta.url && cta.text && (
        <div className={inverse ? 'lg:ml-9' : 'lg:mr-9'}>
          <SmartLink to={cta ? cta.url : null} className="no-underline">
            <GradientButton
              className={classnames(
                'mb-4',
                'lg:mb-0',
                'lg:mt-6',
                'lg:mt-7',
                'mx-1',
                'xl:mx-0'
              )}
            >
              {cta ? cta.text : null}
            </GradientButton>
          </SmartLink>
        </div>
      )}
    </div>
  )

  const MediaColumn = () => (
    <div className="w-full lg:w-1/2">
      <TitleMobile text={title} />
      {!isVideo ? (
        <Image src={media} />
      ) : (
        <VideoWithTitle video={media} poster={poster} />
      )}
    </div>
  )

  return (
    <Container>
      <div
        className={classnames(
          { 'bg-light-grey-4': useBackground },
          'pt-2',
          'pb-4',
          '-mb-6',
          'lg:py-6'
        )}
      >
        <Inner>
          <div
            className={classnames('flex', 'flex-col-reverse', 'lg:flex-row')}
          >
            {inverse ? (
              <>
                <MediaColumn />
                <TextColumn />
              </>
            ) : (
              <>
                <TextColumn />
                <MediaColumn />
              </>
            )}
          </div>
        </Inner>
      </div>
    </Container>
  )
}

export default FiftyFifty
